@import "../../../mixins";

.footer {
  padding: rem(110) 0 rem(90);

  background-color: var(--bg-light);

  @include mediaBigDesktop {
    padding: big(110) 0 big(90);
  }

  @include mediaDesktop {
    padding: rem(90) 0 rem(70);
  }

  @include mediaLaptop {
    padding: rem(70) 0 rem(50);
  }

  @include mediaTablet {
    padding: rem(40) 0 rem(30);
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr auto;
    column-gap: var(--grid-column-gap);
    row-gap: rem(70);

    @include mediaBigDesktop {
      row-gap: big(70);
    }

    @include mediaLaptop {
      grid-template-columns: repeat(4, 1fr);
      row-gap: rem(60);
    }

    @include mediaTablet {
      grid-template-columns: repeat(3, 1fr);
      row-gap: rem(40);
    }

    @include mediaMobile {
      grid-template-columns: repeat(2, 1fr);
      row-gap: rem(20);
    }
  }

  &__logo {
    grid-column: 1/3;
    grid-row: 1/2;

    width: 100%;
    height: fit-content;

    @include mediaLaptop {
      grid-column: 1/2;

      gap: rem(20);
    }

    @include mediaTablet {
      grid-column: 1/4;
    }

    @include mediaMobile {
      grid-column: 1/3;

      gap: rem(15);
    }

    & .logo {
      &__text {
        @include mediaTablet {
          display: flex;
        }
      }
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(20);

    @include mediaBigDesktop {
      gap: big(20);
    }

    @include mediaMobile {
      gap: rem(10);
    }

    &_clear {
      @include mediaLaptop {
        display: none;
      }
    }

    &_contacts {
      @include mediaMobile {
        grid-column: 1/3;
      }
    }

    & ul {
      margin: 0;

      padding: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: rem(10);

      list-style: none;

      @include mediaBigDesktop {
        gap: big(10);
      }

      @include mediaMobile {
        gap: rem(7);
      }

      & li {
        width: 100%;
      }

      & a {
        position: relative;

        width: 100%;
        display: block;

        color: var(--text-dark-primary);
        font: var(--font-body-M-r);
        text-decoration: none;

        transition: color var(--animation-default);

        @include hover {
          color: var(--accent-primary-default);
        }

        &::before {
          @include pseudo(5, false, true);

          @include mediaMobile {
            @include pseudo(3.5, true, false);
          }
        }
      }
    }
  }

  &__title {
    color: var(--text-dark-secondary);
    font: var(--font-body-M-r);
  }

  &__bottom {
    grid-column: 1/7;

    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: rem(30);
    row-gap: rem(10);

    @include mediaBigDesktop {
      column-gap: big(30);
      row-gap: big(10);
    }

    @include mediaLaptop {
      grid-column: 1/5;
    }

    @include mediaTablet {
      grid-column: 1/4;
    }

    @include mediaMobile {
      margin-top: rem(5);
      grid-column: 1/3;
    }
  }

  &__links {
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: rem(50);

    @include mediaBigDesktop {
      gap: big(50);
    }

    @include mediaDesktop {
      gap: rem(40);
    }

    @include mediaTablet {
      gap: rem(30);
    }

    @include mediaMobile {
      row-gap: rem(10);
    }

    & .text {
      color: var(--text-dark-secondary);
      font: var(--font-body-M-r);
      text-decoration: none;
    }

    & a.text {
      transition: color var(--animation-default);

      @include hover {
        color: var(--text-dark-primary);
      }
    }
  }

  &__powered {
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(18);

    color: var(--text-dark-secondary);
    font: var(--font-body-M-r);
    text-decoration: none;

    transition: color var(--animation-default);

    @include mediaBigDesktop {
      gap: big(18);
    }

    @include mediaDesktop {
      gap: rem(15);
    }

    @include hover {
      color: #e05940;
    }

    & img {
      width: rem(30);
      height: rem(30);

      object-fit: contain;
      object-position: center;

      user-select: none;
      pointer-events: none;

      @include mediaBigDesktop {
        width: big(30);
        height: big(30);
      }

      @include mediaDesktop {
        width: rem(24);
        height: rem(24);
      }
    }

    & .text {
      pointer-events: none;
      user-select: none;
    }
  }

  &-social {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: rem(30);

    @include mediaBigDesktop {
      gap: big(30);
    }

    @include mediaDesktop {
      gap: rem(20);
    }

    @include mediaMobile {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: var(--grid-column-gap);
      row-gap: var(--grid-row-gap);
    }

    &__text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: rem(10);

      @include mediaBigDesktop {
        gap: big(10);
      }

      @include mediaMobile {
        gap: rem(5);
      }
    }

    &__call,
    &__email {
      &.button {
        --button-text-color: var(--text-dark-primary);
        --button-text-color-hover: var(--accent-primary-default);
        --button-icon-color: var(--text-dark-primary);
        --button-icon-color-hover: var(--accent-primary-default);
      }
    }

    &__call {
      & .button {
        &__text {
          font: 600 rem(28)/1.4 var(--font-primary);

          @include mediaBigDesktop {
            font: 600 big(28)/1.4 var(--font-primary);
          }

          @include mediaDesktop {
            font: 600 rem(22)/1.4 var(--font-primary);
          }

          @include mediaLaptop {
            font: var(--font-body-L-sb);
          }
        }
      }
    }
  }
}
